body {
  background-color: beige;
}

[contenteditable] {
  outline: 0px solid transparent;
}

.objfitimg {
  object-fit: cover;
}

/*!important needed to override mui*/
.table-header {
  font-size: 1em !important;
  text-align: center !important;
}

.eventTile:hover {
  transform: scale(1.025);
  max-width: 500
}

.offerTagSmall {
  font-size: 10;
  margin-top: 0.2
}
.offerTagBig {
  font-size: 12;
  margin-top: 0.5
}

@tailwind base;
@tailwind components;
@tailwind utilities;
